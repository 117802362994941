import React from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './index.scss';
import Layout from '../../layout';
import Contact from '../../components/contact/contact';
import Helmet from 'react-helmet';
import Slide1 from '../../assets/images/slide-1.jpg';
const { marketingWebUrl } = process.env;
const ogImageUrl = marketingWebUrl + Slide1;

/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title="Contact | Authorization Re-Imagined">
        {/* General tags */}
        <meta name="description" content="" />
        <meta name="image" content={ogImageUrl} />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Contact | Authorization Re-Imagined"
        />
        <meta property="og:description" content="Authorization Re-Imagined" />
        <meta property="og:url" content={marketingWebUrl} />
        <meta property="og:site_name" content="Syccure" />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>
      <Header headerClass={'header haeder-contact'}></Header>
      <Contact />
      <Footer></Footer>
    </Layout>
  );
}

export default Index;
